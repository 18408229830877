
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'IntelligentMachineDetail'
})

export default class extends Vue {
 private activeTab = 'machineDetail'
 private detailId = ''
 created () {
   this.activeTab = this.$route.path.substr(this.$route.path.lastIndexOf('/') + 1)

   const id = this.$route.query.id as string
   this.detailId = id
 }

 changeTab (val: any) {
   this.activeTab = val.name
   this.$router.push({ path: `/rareTreesList/detail/${val.name}`, query: { id: this.detailId } })
 }
}
